import useFetchWrapper from '../utils/useFetchWrapper';

import { AnswerSaved, ApiResponse, ApiStatus, ChatCleared, ChatConfiguration, ChatMessage, ChatResponse, Conversation, ConversationRequest, CosmosDBHealth, CosmosDBStatus, OpenAiInstructions, SnapshotData, UpdatedConversation } from './models'
import { AiResponse } from '../ui/FileUpload/extract';
import { IPersonaProps } from '@fluentui/react';
const useConversationApi = () => {
  const fetchWrapper = useFetchWrapper();

  const conversationApi = (options: ConversationRequest, abortSignal: AbortSignal): Promise<ChatResponse> => {
    return fetchWrapper.post('/api/conversation', {
      messages: options.messages
    }, abortSignal);
  }

  const extractEntities = async (sasUrl: string): Promise<AiResponse> => {
    const response = fetchWrapper.post('/api/extract/entities', {
      sasUrl: sasUrl
    }).then(async res => {
      const payload = await res
      return payload;
    })
    .catch(_err => {
      console.error('There was an issue fetching your data.')
      return null
    })
    return response;
  }

  const historyList = async (offset = 0): Promise<Conversation[] | null> => {
    const response = fetchWrapper.get(`/api/history/list?offset=${offset}`)
      .then(async res => {
        const payload = await res
        if (!Array.isArray(payload)) {
          console.error('There was an issue fetching your data.')
          return null
        }
        const conversations: Conversation[] = await Promise.all(
          payload.map(async (conv: any) => {
            let convMessages: ChatMessage[] = []
            convMessages = await historyRead(conv.id)
              .then(res => {
                return res
              })
              .catch(err => {
                console.error('error fetching messages: ', err)
                return []
              })
            const conversation: Conversation = {
              id: conv.id,
              title: conv.title,
              date: conv.createdAt,
              messages: convMessages
            }
            return conversation
          })
        )
        return conversations
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        return null
      })

    return response
  }

  const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = fetchWrapper.post('/api/history/read', 
      {
        conversation_id: convId
      })
      .then(async res => {
        if (!res) {
          return []
        }
        const payload = await res
        const messages: ChatMessage[] = []
        if (payload?.messages) {
          payload.messages.forEach((msg: any) => {
            const message: ChatMessage = {
              id: msg.id,
              role: msg.role,
              date: msg.createdAt,
              content: msg.content,
              feedback: msg.feedback ?? undefined
            }
            messages.push(message)
          })
        }
        return messages
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        return []
      })
    return response
  }

  const historyGenerate = async (
    options: ConversationRequest,
    abortSignal: AbortSignal,
    convId?: string,
    userMessage?: string,
    selectedFile?: string,
    onlyUploadedFiles?: boolean,
    onlyQMS?: boolean,
    onlyIKNOW?: boolean,
    onlyWIKI?: boolean,
    onlyDMSTEMPLATES?: boolean
  ): Promise<ChatResponse> => {
    let body: OpenAiInstructions = {messages: options.messages}
    if (convId) {
      body.conversation_id = convId;
    }
    if(userMessage) {
      body.userMessage = userMessage;
    }
    if(selectedFile) {
      body.selectedFile = selectedFile;
    }
    if(onlyUploadedFiles) {
      body.onlyUploadedFiles = onlyUploadedFiles;
    }
    if(onlyQMS) {
      body.onlyQMS = onlyQMS;
    }
    if(onlyIKNOW) {
      body.onlyIKNOW = onlyIKNOW;
    }
    if(onlyWIKI) {
      body.onlyWIKI = onlyWIKI;
    }
    if(onlyDMSTEMPLATES) {
      body.onlyDMSTEMPLATES = onlyDMSTEMPLATES;
    }
    const response = fetchWrapper.post('/api/history/generate', 
      body,
      abortSignal
    )
      .then(res => {
        return res
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        return new Response()
      })
    return response
  }

  const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<AnswerSaved> => {
    const response = fetchWrapper.post('/api/history/update', 
      {
        conversation_id: convId,
        messages: messages
      }
    )
      .then(async res => {
        return res
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  const historyDelete = async (convId: string): Promise<Response> => {
    const response = fetchWrapper.delete('/api/history/delete', 
      {
        conversation_id: convId
      }
    )
      .then(() => {
        const successResponse: Response = {
          ...new Response(),
          ok: true,
          status: 200
        }
        return successResponse
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  const historyDeleteAll = async (): Promise<Response> => {
    const response = fetchWrapper.delete('/api/history/delete_all', 
      {})
      .then(() => {
        const successResponse: Response = {
          ...new Response(),
          ok: true,
          status: 200
        }
        return successResponse
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  const historyClear = async (convId: string): Promise<ChatCleared> => {
    const response = fetchWrapper.post('/api/history/clear',
      {
        conversation_id: convId
      })
      .then(res => {
        return res
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  const historyRename = async (convId: string, title: string): Promise<UpdatedConversation> => {
    const response = fetchWrapper.post('/api/history/rename',
      {
        conversation_id: convId,
        title: title
      })
      .then(res => {
        return res
      })
      .catch(_err => {
        console.error('There was an issue fetching your data.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  const retrieveChatConfiguration = (): Promise<ChatConfiguration> => {
    const response = fetchWrapper.get('/api/chat/configurations')
    .then(async res => { return await res})
    .catch(_err => {
      console.error('Failed to fetch system message');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500
      }
      return errRes
    });
    return response;
  }

  const retrieveUploadedFiles = (): Promise<string[]> => {
    const response = fetchWrapper.get('/api/uploadedfiles')
    .then(async res => { return await res})
    .catch(_err => {
      console.error('Failed to fetch uploaded files');
      return [];
    });
    return response;
  }

  const createSnapshot = (convId: string, persons: IPersonaProps[]): Promise<ApiResponse> => {
    const emails = persons.map((person: IPersonaProps) => person.key!)
    const response = fetchWrapper.post('/api/snapshot/create',
      {
        conversation_id: convId,
        emails: emails
      })
    .then(async res => { return await res})
    .catch(_err => {
      console.error('Failed to create a snapshot');
      const errRes: ApiResponse = {status: ApiStatus.error};
      return errRes;
    });
    return response;
  }

  const retrieveSnapshot = (convId: string): Promise<SnapshotData> => {
    const response = fetchWrapper.post('/api/snapshot/retrieve',
      {
        conversation_id: convId
      })
    .then(async res => { return await res})
    .catch(_err => {
      console.error('Failed to retrieve a snapshot');
      return {
        messages: []
      };
    });
    return response;
  }

  const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response =fetchWrapper.get('/api/history/ensure')
      .then(async res => {
        let formattedResponse
        if (res.message) {
          formattedResponse = CosmosDBStatus.Working
        } else {
          if (res.status === 500) {
            formattedResponse = CosmosDBStatus.NotWorking
          } else if (res.status === 401) {
            formattedResponse = CosmosDBStatus.InvalidCredentials
          } else if (res.status === 422) {
            formattedResponse = res.error
          } else {
            formattedResponse = CosmosDBStatus.NotConfigured
          }
        }
        if (formattedResponse !== CosmosDBStatus.Working) {
          return {
            cosmosDB: false,
            status: formattedResponse
          }
        } else {
          return {
            cosmosDB: true,
            status: formattedResponse
          }
        }
      })
      .catch(err => {
        console.error('There was an issue fetching your data.')
        return {
          cosmosDB: false,
          status: err
        }
      })
    return response
  }


  const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = fetchWrapper.post('/api/history/message_feedback', 
      {
        message_id: messageId,
        message_feedback: feedback
      })
      .then(res => {
        return res
      })
      .catch(_err => {
        console.error('There was an issue logging feedback.')
        const errRes: Response = {
          ...new Response(),
          ok: false,
          status: 500
        }
        return errRes
      })
    return response
  }

  return {
    conversationApi,
    historyList,
    historyRead,
    historyGenerate,
    historyUpdate,
    historyDelete,
    extractEntities,
    historyDeleteAll,
    historyClear,
    historyRename,
    historyEnsure,
    historyMessageFeedback,
    retrieveChatConfiguration,
    retrieveUploadedFiles,
    retrieveSnapshot,
    createSnapshot
  }
}

export default useConversationApi;