import { Stack, Text } from "@fluentui/react";
import { AppStateContext } from "../../state/AppProvider";
import { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const EmptyPage: React.FC = () => {

    return (
        <div className="container" style={{ backgroundColor: '#f3f2f1' }}>
      <div className='main-wrapper'>
          <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { height: '100vh' } }}>
              <Text variant="xxLarge" styles={{ root: { color: '#0078d4', fontWeight: 'bold' } }}>
                  Authentication Required
              </Text>
              <Text variant="large" styles={{ root: { marginTop: 20, color: '#605e5c' } }}>
                  Please log in to access this page.
              </Text>
          </Stack>
      </div>
  </div>
    )
}

export default EmptyPage;
