import React, { useContext, useState } from 'react';
import { Stack, PrimaryButton, Text, mergeStyleSets, DetailsList, IColumn, getTheme, IconButton, TextField, IIconProps, ITextFieldStyles } from '@fluentui/react';
import useFetchWrapper from '../../utils/useFetchWrapper';
import { AppStateContext } from '../../state/AppProvider';
import { useNavigate } from 'react-router-dom';

import useConversationApi from '../../api/api';
import { AiResponse } from './extract';
import { Spinner } from '@fluentui/react-components';
import { useDropzone } from 'react-dropzone';

const Upload = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadLink, setUploadLink] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [sasUrl, setSasUrl] = useState<string | undefined>(undefined);
    const appStateContext = useContext(AppStateContext);
    const fetchWrapper = useFetchWrapper();
    const [response, setResponse] = useState<AiResponse | null>(null);
    const [spinnerText, setSpinnerText] = useState<string>('Uploading file to Azure Blob Storage...');
    const navigate = useNavigate();
    const convesationAPi = useConversationApi()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file && file.type !== 'application/pdf') {
            setError('Only PDF files are allowed');
            setSelectedFile(null);
            setFileName(undefined);
        } else {
            setError(null);
            setSelectedFile(file);
            setFileName(file ? file.name : undefined);
            setLoading(false)
        }
    };

    const uploadIcon: IIconProps = { iconName: 'Upload' };
    const textFieldStyles: Partial<ITextFieldStyles> = {
        fieldGroup: { width: 300 },
    };


    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            setSelectedFile(selectedFile);
            setFileName(selectedFile.name);
            setError(null);
            setLoading(false);
        }
    }, []);

    const fileType = { 'application/pdf': ['.pdf'] };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: fileType,
        multiple: false,
        maxFiles: 1
    });

    const startAConversation = async () => {
        appStateContext?.dispatch({ type: 'SET_SELECTED_FILE', payload: uploadLink });
        appStateContext?.dispatch({ type: 'SET_ONLY_UPLOADED_FILES', payload: true });
        appStateContext?.dispatch({ type: 'SET_ONLY_QMS', payload: false });
        appStateContext?.dispatch({ type: 'SET_ONLY_IKNOW', payload: false });
        appStateContext?.dispatch({ type: 'SET_ONLY_WIKI', payload: false });
        appStateContext?.dispatch({ type: 'SET_ONLY_DMSTEMPLATES', payload: false });
        navigate("/openai");
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;
        setError(null);
        setSpinnerText('Uploading file to Azure Blob Storage...');
        setLoading(true);
        setUploadLink(undefined);
        setSelectedFile(null);
        setFileName(undefined);
        setSasUrl(undefined);
        setResponse(null);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('file_name', selectedFile.name);
        await fetchWrapper.postFormData('/api/azurestorage/uploadsastoken', formData)
            .then(res => {
                const files = appStateContext?.state.uploadedFiles;
                if (files !== undefined && files.length > 0) {
                    files.push(res.file_name);
                    appStateContext?.dispatch({ type: 'SET_UPLOADED_FILES', payload: files });
                } else {
                    appStateContext?.dispatch({ type: 'SET_UPLOADED_FILES', payload: [res.file_name] });
                }
                if (res.sasUrl !== undefined) {
                    setSasUrl(res.sasUrl);
                }
                setUploadLink(res.file_name);
            })
            .catch(error => {
                console.error(error);
                setError("Failed to upload the file..");
            })
            .finally(() => setLoading(false));
    };
    
    const handleFileClear = async () => {
        // if (!selectedFile) return;
        setError(null);
        setLoading(true);
        setSpinnerText('Deleting all of your uploaded documents and their indexes from AiVar...');
        setResponse(null);
        await fetchWrapper.get('/api/azurestorage/clearuseruploads')
            .then(res => {
                console.info("Successfully removed all user uploaded files..");
            })
            .catch(error => {
                console.error(error);
                setError("Failed to clear uploaded files..");
            })
            .finally(() => {
                setUploadLink(undefined);
                setSelectedFile(null);
                setFileName(undefined);
                setSasUrl(undefined);
                setLoading(false)
            });
    };

    const handleAIModelExecution = async () => {
        setSpinnerText('Runnin Entity extraction AI model trained on Sorainen data...');
        setLoading(true);
        if (sasUrl !== undefined) {
            const res = await convesationAPi.extractEntities(sasUrl);
            if (res) {
                setResponse(res);
            }
        }
        setLoading(false);
    };

    const theme = getTheme();

    const classes = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 20,
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            boxShadow: theme.effects.elevation8,
            flexGrow: 1,
            maxWidth: '80%',
            overflow: 'auto', // Add this line to handle overflow
            maxHeight: '100vh', // Optional: To restrict the height of the container
        },
        descriptionBox: {
            padding: 20,
            backgroundColor: theme.palette.neutralLighter,
            borderRadius: 10,
            textAlign: 'center',
            marginBottom: 20,
            flexShrink: 0,
        },
        uploadBox: {
            border: `2px dashed ${theme.palette.neutralTertiary}`,
            padding: '20px 20px',
            borderRadius: 10,
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 20,
            flexShrink: 0,
        },
        uploadButton: {
            backgroundColor: selectedFile ? theme.palette.themePrimary : theme.palette.neutralTertiary,
            color: theme.palette.white,
        },
        deleteButton: {
            backgroundColor: theme.palette.themePrimary,
            color: theme.palette.white,
        },
    });

    const columns: IColumn[] = [
        { key: 'field', name: 'Field', fieldName: 'field', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'value', name: 'Value', fieldName: 'value', minWidth: 100, maxWidth: 300, isResizable: true },
    ];

    const renderFieldObjects = (fieldObjects: AiResponse) => {
        return Object.keys(fieldObjects).map((key) => {
            const fieldObject = fieldObjects[key];
            const items = [
                { field: 'value', value: fieldObject.value },
                { field: 'displayName', value: fieldObject.displayName },
                { field: 'fieldType', value: fieldObject.fieldType },
                { field: 'confidence', value: fieldObject.confidence },
                { field: 'text', value: fieldObject.text },
            ];
            if (fieldObject.fieldType) {
                return (
                    <div key={key}>
                        <DetailsList items={items} columns={columns} />
                    </div>
                );
            } else {
                return (<>
                    <Stack className={classes.descriptionBox}>
                        <Text variant="large">No entities found...</Text>
                    </Stack>
                </>)
            }
        });
    };


    return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { backgroundColor: '#f3f2f1', height: '100vh', overflow: 'auto' } }}>
            <Stack className={classes.container}>
                <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 20, minWidth: '80%' } }}>
                    {loading ? (
                        <Spinner appearance="primary" label={spinnerText} size="large" className={classes.descriptionBox} style={{ minWidth: '80%' }} />
                    ) : (
                        <>
                            <Stack className={classes.descriptionBox}>
                                <Text variant="large">Document Upload</Text>
                                <Text variant="medium" style={{ textAlign: 'justify' }}>
                                    Use this form to upload a PDF document. Once uploaded, you can either start a conversation using OpenAI integration on the file or use Sorainen AI extraction model.
                                </Text>
                            </Stack>
                            <Text variant="large">Select or drag 'n' drop file to upload</Text>
                            <Stack tokens={{ childrenGap: 10 }} {...getRootProps()} styles={{ root: { border: '1px dashed #ccc', padding: 20, width:'100%', textAlign: 'center' } }}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some pdf file here, or click to select files</p>
                                <em>(Upload is currently limitted to one file)</em>
                            </Stack>
                            {fileName && (
                            <Text variant="medium" styles={{ root: { marginBottom: 10 } }}>
                                Selected file: {fileName}
                            </Text>
                            )}
                            {error && <Text variant="small" styles={{ root: { color: 'red' } }}>{error}</Text>}
                            <PrimaryButton
                                className={classes.uploadButton}
                                onClick={handleFileUpload}
                                disabled={!selectedFile}
                            >
                                Upload file
                            </PrimaryButton>
                            {uploadLink && (
                                <>
                                    <Stack className={classes.descriptionBox}>
                                        <Text variant="medium">
                                            File uploaded: {uploadLink}
                                        </Text>
                                    </Stack>
                                    <PrimaryButton
                                        styles={{ root: { width: '100%', marginBottom: 10 } }}
                                        onClick={startAConversation}
                                    >
                                        Start a conversation on this file
                                    </PrimaryButton>
                                    {undefined && sasUrl && (
                                        <>
                                            <Stack className={classes.descriptionBox}>
                                                <Text variant="medium">
                                                    Do you want an AI model to be executed on this uploaded file?
                                                </Text>
                                                <Text variant="smallPlus">
                                                    It is an internally developed AI entity extractor that is executed on the uploaded PDF file.
                                                </Text>
                                            </Stack>
                                            <PrimaryButton
                                                styles={{ root: { width: '100%' } }}
                                                onClick={handleAIModelExecution}
                                            >
                                                Execute AI Model
                                            </PrimaryButton>
                                            <Stack className={classes.descriptionBox}>
                                                {response && renderFieldObjects(response)}
                                            </Stack>
                                        </>
                                    )}

                                </>)}
                                <PrimaryButton
                                    className={classes.deleteButton}
                                    onClick={handleFileClear}
                                >
                                    Delete all uploaded files
                                </PrimaryButton>
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Upload;
