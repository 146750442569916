import styles from './Button.module.css'
import React from 'react';
import { ClipboardRegular } from '@fluentui/react-icons';

const CopyButton: React.FC<{ text: string, tooltipText: string }> = ({ text, tooltipText }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <button 
      onClick={copyToClipboard} 
      className={styles.copyButton}
      aria-label="Copy to clipboard"
    >
      <ClipboardRegular />
      <span className={styles.tooltipText}>{tooltipText}</span>
    </button>
  );
};

export { CopyButton };