import { Action, AppState } from './AppProvider';

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'TOGGLE_CHAT_HISTORY':
      return { ...state, isChatHistoryOpen: !state.isChatHistoryOpen };
    case 'SET_AUTHENTICATING':
      return { ...state, authenticating: !state.authenticating };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_AVATAR_URL':
      return { ...state, avatarURL: action.payload };
    case 'SET_SYSTEM_MESSAGE':
      return { ...state, systemMessage: action.payload};
    case 'SET_UPLOADED_FILES':
      return { ...state, uploadedFiles: action.payload};
    case 'UPDATE_CURRENT_CHAT':
      return { ...state, currentChat: action.payload };
    case 'SET_ONLY_UPLOADED_FILES':
      return { ...state, onlyUploadedFiles: action.payload };
    case 'SET_ONLY_QMS':
      return { ...state, onlyQMS: action.payload };
    case 'SET_ONLY_IKNOW':
      return { ...state, onlyIKNOW: action.payload };
    case 'SET_ONLY_WIKI':
      return { ...state, onlyWIKI: action.payload };
    case 'SET_ONLY_DMSTEMPLATES':
      return { ...state, onlyDMSTEMPLATES: action.payload };
    case 'SET_SELECTED_FILE':
      return { ...state, selectedFile: action.payload};
    case 'SET_USER_MESSAGE':
      return { ...state, userMessage: action.payload};
    case 'UPDATE_CHAT_HISTORY_LOADING_STATE':
      return { ...state, chatHistoryLoadingState: action.payload };
    case 'UPDATE_CHAT_HISTORY':
      if (!state.chatHistory || !state.currentChat) {
        return state;
      }
      const conversationIndex = state.chatHistory.findIndex(conv => conv.id === action.payload.id);
      if (conversationIndex !== -1) {
        const updatedChatHistory = [...state.chatHistory];
        updatedChatHistory[conversationIndex] = state.currentChat;
        return { ...state, chatHistory: updatedChatHistory };
      } else {
        return { ...state, chatHistory: [...state.chatHistory, action.payload] };
      }
    case 'UPDATE_CHAT_TITLE':
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      const updatedChats = state.chatHistory.map(chat => {
        if (chat.id === action.payload.id) {
          if (state.currentChat?.id === action.payload.id) {
            state.currentChat.title = action.payload.title;
          }
          return { ...chat, title: action.payload.title };
        }
        return chat;
      });
      return { ...state, chatHistory: updatedChats };
    case 'DELETE_CHAT_ENTRY':
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      const filteredChat = state.chatHistory.filter(chat => chat.id !== action.payload);
      state.currentChat = null;
      return { ...state, chatHistory: filteredChat };
    case 'DELETE_CHAT_HISTORY':
      return { ...state, chatHistory: [], filteredChatHistory: [], currentChat: null };
    case 'DELETE_CURRENT_CHAT_MESSAGES':
      if (!state.currentChat || !state.chatHistory) {
        return state;
      }
      const updatedCurrentChat = {
        ...state.currentChat,
        messages: []
      };
      return {
        ...state,
        currentChat: updatedCurrentChat
      };
    case 'FETCH_CHAT_HISTORY':
      return { ...state, chatHistory: action.payload };
    case 'SET_COSMOSDB_STATUS':
      return { ...state, isCosmosDBAvailable: action.payload };
    case 'FETCH_FRONTEND_SETTINGS':
      return { ...state, frontendSettings: action.payload };
    case 'SET_FEEDBACK_STATE':
      return {
        ...state,
        feedbackState: {
          ...state.feedbackState,
          [action.payload.answerId]: action.payload.feedback
        }
      };
    default:
      return state;
  }
};
