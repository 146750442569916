import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useConversationApi from "../../api/api";
import styles from '../OpenAI/OpenAI.module.css';
import { IconButton, Stack } from '@fluentui/react'
import { ErrorCircleRegular } from '@fluentui/react-icons'
import { AppStateContext } from "../../state/AppProvider";
import { AzureSqlServerExecResults, ChatMessage, Citation, ExecResults, ToolMessageContent } from "../../api/models";
import { Answer } from "../../components/Answer";
import ReactMarkdown from "react-markdown";
import DOMPurify from "dompurify";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { XSSAllowTags } from "../../constants/xssAllowTags";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const ReadOnlyOpenAI = () => {
    let { id } = useParams();
    const convesationAPi = useConversationApi()
    const appStateContext = useContext(AppStateContext)
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [activeCitation, setActiveCitation] = useState<Citation>()
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false)
    const [isIntentsPanelOpen, setIsIntentsPanelOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)  
    const [execResults, setExecResults] = useState<ExecResults[]>([])

    useEffect(() => {
        async function retrieveSnapshot() {
            setIsLoading(true);
            if (id !== undefined) {
                const snapshotData = await convesationAPi.retrieveSnapshot(id);
                if(snapshotData.messages.length > 0) {
                    setMessages(snapshotData.messages);
                }
            }
            setIsLoading(false);
        }
        if(messages.length === 0) {
            retrieveSnapshot()
        }
    }, [messages])

    const [ASSISTANT, TOOL, ERROR] = ['assistant', 'tool', 'error']

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes('blob.core')) {
          window.open(citation.url, '_blank')
        }
      }

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === 'tool') {
          try {
            const toolMessage = JSON.parse(message.content) as ToolMessageContent
            return toolMessage.citations
          } catch {
            return []
          }
        }
        return []
      }
    
      const parsePlotFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
          try {
            const execResults = JSON.parse(message.content) as AzureSqlServerExecResults;
            const codeExecResult = execResults.all_exec_results.at(-1)?.code_exec_result;
            if (codeExecResult === undefined) {
              return null;
            }
            return codeExecResult;
          }
          catch {
            return null;
          }
        }
        return null;
      }

      const onShowCitation = (citation: Citation) => {
        setActiveCitation(citation)
        setIsCitationPanelOpen(true)
      }
    
      const onShowExecResult = () => {
        setIsIntentsPanelOpen(true)
      }

    return (
        <div className={styles.container} role="main">
    <Stack horizontal className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            {!messages || messages.length < 1 ? (
        <Stack className={styles.chatEmptyState}>
          <h1 className={styles.chatEmptyStateTitle}>You don't have access rights or conversation doesn't exist</h1>
        </Stack>
      ) : (
        <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? '40px' : '0px' }} role="log">
          {messages.map((answer, index) => (
            <>
              {answer.role === 'user' ? (
                <div className={styles.chatMessageUser} tabIndex={0}>
                  <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                </div>
              ) : answer.role === 'assistant' ? (
                <div className={styles.chatMessageGpt}>
                  <Answer
                    answer={{
                      answer: answer.content,
                      citations: parseCitationFromMessage(messages[index - 1]),
                      plotly_data: parsePlotFromMessage(messages[index - 1]),
                      message_id: answer.id,
                      feedback: undefined,
                      exec_results: execResults
                    }}
                    onCitationClicked={c => onShowCitation(c)}
                    onExectResultClicked={() => onShowExecResult()}
                  />
                </div>
              ) : answer.role === ERROR ? (
                <div className={styles.chatMessageError}>
                  <Stack horizontal className={styles.chatMessageErrorContent}>
                    <ErrorCircleRegular className={styles.errorIcon} style={{ color: 'rgba(182, 52, 67, 1)' }} />
                    <span>Error</span>
                  </Stack>
                  <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                </div>
              ) : null}
            </>
          ))}
            
          </div>)}
          </div>
          {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
            <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
              <Stack
                aria-label="Citations Panel Header Container"
                horizontal
                className={styles.citationPanelHeaderContainer}
                horizontalAlign="space-between"
                verticalAlign="center">
                <span aria-label="Citations" className={styles.citationPanelHeader}>
                  Citations
                </span>
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  aria-label="Close citations panel"
                  onClick={() => setIsCitationPanelOpen(false)}
                />
              </Stack>
              <h5
                className={styles.citationPanelTitle}
                tabIndex={0}
                title={
                  activeCitation.url && !activeCitation.url.includes('blob.core')
                    ? activeCitation.url
                    : activeCitation.title ?? ''
                }
                onClick={() => onViewSource(activeCitation)}>
                {activeCitation.title}
              </h5>
              <div tabIndex={0}>
                <ReactMarkdown
                  linkTarget="_blank"
                  className={styles.citationPanelContent}
                  children={DOMPurify.sanitize(activeCitation.content, { ALLOWED_TAGS: XSSAllowTags })}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </Stack.Item>
          )}
          {messages && messages.length > 0 && isIntentsPanelOpen && (
            <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Intents Panel">
              <Stack
                aria-label="Intents Panel Header Container"
                horizontal
                className={styles.citationPanelHeaderContainer}
                horizontalAlign="space-between"
                verticalAlign="center">
                <span aria-label="Intents" className={styles.citationPanelHeader}>
                  Intents
                </span>
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  aria-label="Close intents panel"
                  onClick={() => setIsIntentsPanelOpen(false)}
                />
              </Stack>
              <Stack horizontalAlign="space-between">
                {execResults.map((execResult) => {
                  return (
                    <Stack className={styles.exectResultList} verticalAlign="space-between">
                      <><span>Intent:</span> <p>{execResult.intent}</p></>
                      {execResult.search_query && <><span>Search Query:</span>
                        <SyntaxHighlighter
                          style={nord}
                          wrapLines={true}
                          lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                          language="sql"
                          PreTag="p">
                          {execResult.search_query}
                        </SyntaxHighlighter></>}
                      {execResult.search_result && <><span>Search Result:</span> <p>{execResult.search_result}</p></>}
                      {execResult.code_generated && <><span>Code Generated:</span>
                        <SyntaxHighlighter
                          style={nord}
                          wrapLines={true}
                          lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                          language="python"
                          PreTag="p">
                          {execResult.code_generated}
                        </SyntaxHighlighter>
                      </>}
                    </Stack>
                  )
                })}
              </Stack>
            </Stack.Item>
          )}
        </Stack></div>);
}

export default ReadOnlyOpenAI;