import { createRef, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Dialog, Stack, TextField, Dropdown, PrimaryButton, MessageBar, MessageBarType, Spinner, mergeStyleSets, Label, Checkbox, Toggle } from '@fluentui/react'
import { Accordion, AccordionItem, AccordionHeader, AccordionPanel } from '@fluentui/react-components';
import { CopyRegular } from '@fluentui/react-icons'
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import {
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSelectedProps,
    ITag,
    NormalPeoplePicker,
    PeoplePickerItem,
    ValidationState,
} from '@fluentui/react/lib/Pickers';
import { ApiStatus, CosmosDBStatus } from '../../api'
import { HistoryButton, ShareButton, SettingButton } from '../../components/common/Button'
import { AppStateContext } from '../../state/AppProvider'
import styles from './OpenAiLayout.module.css'
import useConversationApi from '../../api/api'
import { Client } from '@microsoft/microsoft-graph-client';
import { Person } from '@microsoft/microsoft-graph-types';
import React from 'react'
import './Settings.css';
 

interface DropdownOption {
    key: string;
    text: string;
}
type SettingsProps = {
    open: boolean;
    onDismiss: () => void;
  };
  
const Settings = ({open, onDismiss}: SettingsProps) => {
    const appStateContext = useContext(AppStateContext)
    const [errorMessage, setErrorMessage] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState<DropdownOption[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [onlyUploadedFiles, setOnlyUploadedFiles] = useState(false);
    const [onlyQMS, setQMS] = useState(true);
    const [onlyIKNOW, setIKNOW] = useState(false);
    const [onlyWIKI, setWIKI] = useState(false);
    const [onlyDMSTEMPLATES, setDMSTEMPLATES] = useState(false);

    useEffect(() => {
        if(appStateContext?.state.userMessage) {
          setUserMessage(appStateContext?.state.userMessage);
        }
      }, []) 

    useEffect(() => {
        setOnlyUploadedFiles(appStateContext?.state.onlyUploadedFiles ?? false);
        setQMS(appStateContext?.state.onlyQMS ?? false);
        setIKNOW(appStateContext?.state.onlyIKNOW ?? false);
        setDMSTEMPLATES(appStateContext?.state.onlyDMSTEMPLATES ?? false);
    }, [appStateContext?.state.onlyUploadedFiles, appStateContext?.state.onlyQMS, appStateContext?.state.onlyIKNOW, appStateContext?.state.onlyDMSTEMPLATES]);

    useEffect(() => {
        if(appStateContext?.state.uploadedFiles.length === 0) {
          setSelectedValue('');
        } else {
            if(appStateContext?.state.selectedFile) {
                setSelectedValue(appStateContext.state.selectedFile);
            }
        }
      }, [appStateContext?.state.uploadedFiles])


    useEffect(() => {
        function convertToDropdownOptions(arr: string[]): DropdownOption[] {
            return arr.map((item) => {
                const parts = item.split('#');
                if (parts.length <= 1) { // '/tmp/uuid#filename.pdf'
                    return {
                        key: item,
                        text: item,
                    };
                }
                
                const pathParts = parts[0].split('/');
                const itemUuid = pathParts[pathParts.length - 1];
                const itemFilename = parts[parts.length - 1];
                return {
                  key: item,
                  text: `${itemFilename} (${itemUuid})`,
                };
            });
        }
        if(appStateContext?.state.uploadedFiles !== undefined) {
            setUploadedFiles(convertToDropdownOptions(appStateContext?.state.uploadedFiles));
        }
      }, [appStateContext?.state.uploadedFiles])
    


    const handleSettingsPanelDismiss = () => {
        onDismiss()
    }

    const handleCheckboxChangeFiles = (even: any, checked: any) => {
        setOnlyUploadedFiles(true);
        setQMS(false);
        setIKNOW(false);
        setDMSTEMPLATES(false);
      };

    const handleCheckboxChangeQMS = (even: any, checked: any) => {
        setOnlyUploadedFiles(false);
        setQMS(true);
        setIKNOW(false);
        setDMSTEMPLATES(false);

    };
    const handleCheckboxChangeIKNOW = (even: any, checked: any) => {
        setOnlyUploadedFiles(false);
        setQMS(false);
        setIKNOW(true);
        setDMSTEMPLATES(false);
    };
    const handleCheckboxChangeWIKI = (even: any, checked: any) => {
        setWIKI(checked);
    };
    const handleCheckboxChangeDMSTEMPLATES = (even: any, checked: any) => {
        setOnlyUploadedFiles(false);
        setQMS(false);
        setIKNOW(false);
        setDMSTEMPLATES(true);
    };
    
    const handleSettingsSubmit = () => {
        appStateContext?.dispatch({ type: 'SET_USER_MESSAGE', payload: userMessage });
        appStateContext?.dispatch({ type: 'SET_SELECTED_FILE', payload: selectedValue });
        appStateContext?.dispatch({ type: 'SET_ONLY_UPLOADED_FILES', payload: onlyUploadedFiles });
        appStateContext?.dispatch({ type: 'SET_ONLY_QMS', payload: onlyQMS });
        appStateContext?.dispatch({ type: 'SET_ONLY_IKNOW', payload: onlyIKNOW });
        appStateContext?.dispatch({ type: 'SET_ONLY_WIKI', payload: onlyWIKI });
        appStateContext?.dispatch({ type: 'SET_ONLY_DMSTEMPLATES', payload: onlyDMSTEMPLATES })
        onDismiss()
        setErrorMessage('')
    }

    const handleDrowdownChange = (even: any, option: any) => {
        setSelectedValue(option.text);
        console.log(option.text);
    };

    const toggleStyles = {
        root: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        label: {
            margin: '0 10px',
        },
    };
    const stackItemStyles = {
        root: {
            width: '100%',
            height: '24px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    };


    return (
        <Dialog
            onDismiss={handleSettingsPanelDismiss}
            hidden={!open}
            modalProps={{
                isBlocking: false, // Adjust blocking as needed
                styles: { main: { minWidth: '50%', maxWidth: '80%' } },
            }}
            styles={{
                main: [
                    {
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                width: '60%',
                                maxWidth: '600px',
                                background: '#FFFFFF',
                                boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                                borderRadius: '8px',
                                minHeight: '200px'
                            }
                        },
                        boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }
                ]
            }}
            dialogContentProps={{
                title: 'Set chat settings',
                showCloseButton: true
            }}
        >
            {errorMessage && (
                <Stack.Item style={{ width: '100%' }}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => setErrorMessage('')}
                        dismissButtonAriaLabel="Close"
                    >
                        {errorMessage}
                        <br />
                        <a href='/#/upload'>Upload a file...</a>
                    </MessageBar>
                </Stack.Item>
            )}
            <Stack style={{ gap: '4px', width: '100%' }}>
                <Stack.Item style={{ width: '100%', marginBottom: '18px' }}>
                    <Dropdown
                        label='Uploaded files'
                        placeholder="Select an option"
                        options={[{ key: '', text: 'All' }, ...uploadedFiles]}
                        selectedKey={selectedValue}
                        onChange={handleDrowdownChange}
                        styles={{ root: { width: '100%' } }}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <Toggle
                    label="Chat about uploaded documents"
                    checked={onlyUploadedFiles}
                    onChange={handleCheckboxChangeFiles}
                    styles={toggleStyles}
                    className={`custom-toggle`}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <Toggle
                    label="Chat about QMS"
                    checked={onlyQMS}
                    onChange={handleCheckboxChangeQMS}
                    styles={toggleStyles}
                    className={`custom-toggle`}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <Toggle
                    label="Chat about iKnow"
                    checked={onlyIKNOW}
                    onChange={handleCheckboxChangeIKNOW}
                    styles={toggleStyles}
                    className={`custom-toggle`}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <Toggle
                    label="Chat about DMS Templates"
                    checked={onlyDMSTEMPLATES}
                    onChange={handleCheckboxChangeDMSTEMPLATES}
                    styles={toggleStyles}
                    className={`custom-toggle`}
                    />
                </Stack.Item>
                <Accordion collapsible>
                    <AccordionItem value={0}>
                        <AccordionHeader>Advanced Settings</AccordionHeader>
                        <AccordionPanel>
                            <Stack.Item style={{ width: '100%' }}>
                                <TextField
                                    multiline
                                    rows={3}
                                    label='System message'
                                    readOnly
                                    styles={{ root: { width: '100%' } }}
                                    value={appStateContext?.state.systemMessage}
                                />
                            </Stack.Item>
                            <Stack.Item style={{ width: '100%' }}>
                                <TextField
                                    multiline
                                    rows={3}
                                    value={userMessage}
                                    onChange={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        if (newValue) {
                                            setUserMessage(newValue)
                                        } else {
                                            setUserMessage('')
                                        }
                                    }}
                                    placeholder="Add hint to AiVar"
                                    name='userMessage'
                                    label='User message'
                                    styles={{ root: { width: '100%' } }}
                                />
                            </Stack.Item>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <Stack.Item style={{ width: '100%', marginTop: '16px' }}>
                    <PrimaryButton text="Apply settings" onClick={handleSettingsSubmit} styles={{ root: { width: '100%' } }} />
                </Stack.Item>
            </Stack>
        </Dialog>
    );
}

export default Settings;