import { useContext } from "react";
import { AppStateContext } from "../state/AppProvider";
import { AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

interface RequestOptions {
  method: string;
  headers: Headers;
  body?: string | FormData;
  signal?: AbortSignal;
}

interface FetchWrapper {
  get: (url: string, signal?: AbortSignal) => Promise<any>;
  post: (url: string, body: any, signal?: AbortSignal) => Promise<any>;
  put: (url: string, body: any, signal?: AbortSignal) => Promise<any>;
  delete: (url: string, body: any, signal?: AbortSignal) => Promise<any>;
  postFormData: (url: string, body: FormData, signal?: AbortSignal) => Promise<any>;
  postWithAccessToken: (url: string, body: any) => Promise<any>;
}

export default function useFetchWrapper(): FetchWrapper {
  const appContext = useContext(AppStateContext)!;
  const { instance } = useMsal();
  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    postFormData,
    postWithAccessToken
  };

  async function postFormData(url: string, body: FormData, signal?: AbortSignal): Promise<any> {
    let hed;
    if(appContext.acquireToken) {
      const token = await appContext.acquireToken();
      hed = await authHeader(token)
    } else {
      hed = await authHeader()
    }
    const requestOptions: RequestOptions = {
      method: 'POST',
      headers: hed,
      body,
      signal
    };

    let response = await fetch(url, requestOptions);

    return await handleResponse(response);
  }

  async function postWithAccessToken(url: string, body: any): Promise<any> {
    if(appContext?.acquireToken) {
      const res = await appContext?.acquireToken();
      const requestOptions: RequestOptions = {
        method: "post",
        headers: await authHeader(res)
      };
      requestOptions.headers.set('Content-Type', 'application/json');
      requestOptions.body = JSON.stringify(body);
      let response = await fetch(url, requestOptions);
      return await handleResponse(response);
    }
    return null;
  }

  function request(method: string) {
    return async (url: string, body?: any, signal?: AbortSignal): Promise<any> => {
      const requestOptions: RequestOptions = {
        method,
        headers: await authHeader(),
        signal: signal
      };

      if (body) {
        requestOptions.headers.set('Content-Type', 'application/json');
        requestOptions.body = JSON.stringify(body);
      }
      let response = await fetch(url, requestOptions);

      if (response.status === 401) {
        const silentRequest = loginRequest;
        if(appContext.state.name) {
          silentRequest.loginHint = appContext.state.name;
        }
        let res
        try {
          res = await instance.acquireTokenSilent(silentRequest);
        } catch (ex) {
          console.log(ex);
        }
        requestOptions.headers = await authHeader(res);
        response = await fetch(url, requestOptions);
      }

      return await handleResponse(response);
    };
  }

  async function authHeader(result?: AuthenticationResult): Promise<Headers> {
    // return auth header with jwt if user is logged in and request is to the api url
    let token, access_token
    if(result !== undefined) {
      token = result.idToken;
      access_token = result.accessToken;
    } else if(appContext?.getAccount) {
      if(appContext?.getAccount() !== null) {
        token = appContext.getAccount()?.idToken;
        access_token = appContext.state.accessToken;
      } else if(appContext?.acquireToken) {
        const res = await appContext.acquireToken();
        token = res.idToken;
        access_token = res.accessToken;
      }
    }
    const headers = new Headers();
    if(!!access_token) {
      headers.append("X-Ms-Access-token", access_token);
    }
    if (!!token) {
        headers.append("Authorization","Bearer " + token);
      return headers;
    } 
    return headers;
  }

  async function handleResponse(response: Response) {
    const text = await response.text();
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  }
}
