import React, { createContext, ReactNode, useEffect, useReducer, useCallback, useMemo } from 'react';
import requiresInteraction from '../utils/requiresInteraction';
import { appStateReducer } from './AppReducer';

import { ChatHistoryLoadingState, Conversation, CosmosDBHealth, CosmosDBStatus, Feedback, FrontendSettings } from '../api'

import fetchProfilePhotoAsBase64 from '../oauth/fetchProfilePhotoAsBase64';
import { loadState, saveState } from '../utils/localStorage';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  SEND_MAIL: 'Mail.Send',
  PEOPLE: 'People.Read',
  PEOPLE_ALL: 'People.Read.All'
};

const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ, GRAPH_SCOPES.SEND_MAIL, GRAPH_SCOPES.PEOPLE, GRAPH_SCOPES.PEOPLE_ALL],
    loginHint: "",
    forceRefresh: true,
    account: undefined as AccountInfo | undefined
  }
};

export interface AppState {
  isChatHistoryOpen: boolean;
  systemMessage: string | undefined;
  chatHistoryLoadingState: ChatHistoryLoadingState;
  isCosmosDBAvailable: CosmosDBHealth;
  chatHistory: Conversation[] | null;
  filteredChatHistory: Conversation[] | null;
  currentChat: Conversation | null;
  frontendSettings: FrontendSettings | null;
  avatarURL: string | undefined;
  name: string | undefined;
  userMessage: string | undefined;
  selectedFile: string | undefined;
  authenticating: boolean;
  feedbackState: { [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative };
  uploadedFiles: string[];
  onlyUploadedFiles: boolean;
  onlyQMS: boolean;
  onlyIKNOW: boolean;
  onlyWIKI: boolean;
  onlyDMSTEMPLATES: boolean;
  accessToken: string | undefined;
}

export type Action =
  | { type: 'TOGGLE_CHAT_HISTORY' }
  | { type: 'SET_COSMOSDB_STATUS'; payload: CosmosDBHealth }
  | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE'; payload: ChatHistoryLoadingState }
  | { type: 'UPDATE_CURRENT_CHAT'; payload: Conversation | null }
  | { type: 'UPDATE_FILTERED_CHAT_HISTORY'; payload: Conversation[] | null }
  | { type: 'UPDATE_CHAT_HISTORY'; payload: Conversation }
  | { type: 'UPDATE_CHAT_TITLE'; payload: Conversation }
  | { type: 'DELETE_CHAT_ENTRY'; payload: string }
  | { type: 'DELETE_CHAT_HISTORY' }
  | { type: 'DELETE_CURRENT_CHAT_MESSAGES'; payload: string }
  | { type: 'FETCH_CHAT_HISTORY'; payload: Conversation[] | null }
  | { type: 'FETCH_FRONTEND_SETTINGS'; payload: FrontendSettings | null }
  | { type: 'SET_FEEDBACK_STATE'; payload: { answerId: string; feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral } }
  | { type: 'GET_FEEDBACK_STATE'; payload: string }
  | { type: 'SET_AVATAR_URL'; payload: string | undefined }
  | { type: 'SET_NAME'; payload: string | undefined }
  | { type: 'SET_AUTHENTICATING'; payload: boolean }
  | { type: 'SET_UPLOADED_FILES'; payload: string[] }
  | { type: 'SET_SYSTEM_MESSAGE'; payload: string | undefined }
  | { type: 'SET_SELECTED_FILE'; payload: string | undefined }
  | { type: 'SET_ACCESS_TOKEN'; payload: string | undefined }
  | { type: 'SET_ONLY_UPLOADED_FILES'; payload: boolean }
  | { type: 'SET_ONLY_QMS'; payload: boolean }
  | { type: 'SET_ONLY_IKNOW'; payload: boolean }
  | { type: 'SET_ONLY_WIKI'; payload: boolean }
  | { type: 'SET_ONLY_DMSTEMPLATES'; payload: boolean }
  | { type: 'SET_USER_MESSAGE'; payload: string | undefined };

const initialState: AppState = {
  isChatHistoryOpen: true,
  systemMessage: undefined,
  chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
  chatHistory: null,
  filteredChatHistory: null,
  currentChat: null,
  uploadedFiles: [],
  isCosmosDBAvailable: {
    cosmosDB: true,
    status: CosmosDBStatus.Working
  },
  frontendSettings: {
    auth_enabled: "true",
    ui: {
      "title": "Sorainen",
      "chat_title": "Chat about uploaded documents",
      "chat_description": "Chat about legal documents",
      "show_share_button": true
    }
  },
  feedbackState: {},
  avatarURL: undefined,
  name: undefined,
  authenticating: false,
  userMessage: undefined,
  selectedFile: undefined,
  onlyUploadedFiles: false,
  onlyQMS: true,
  onlyIKNOW: false,
  onlyWIKI: false,
  onlyDMSTEMPLATES: false,
  accessToken: undefined
};

export const AppStateContext = createContext<
  | {
    state: AppState;
    dispatch: React.Dispatch<Action>;
    onSignIn?: () => void;
    onSignOut?: () => void;
    acquireToken?: () => Promise<AuthenticationResult>;
    getAccount?: () => AccountInfo | null;
  }
  | undefined
>(undefined);

type AppStateProviderProps = {
  children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
  const persistedState = loadState('appState') || initialState;
  const [state, dispatch] = useReducer(appStateReducer, persistedState);

  const { instance } = useMsal();

  useEffect(() => {
    saveState('appState', state);
  }, [state]);

  
  const getAccount = useCallback(() => instance?.getActiveAccount() || null, [instance]);

  const acquireToken = useCallback(
    async (request = GRAPH_REQUESTS.LOGIN) => {
      if (instance) {
        if (state.name) {
          request.loginHint = state.name;
        }
        const account = getAccount();
        if (account) {
          request.account = account;
        } 
        request.forceRefresh = true;
        const res = await instance.acquireTokenSilent(request).catch(error => {
          if (requiresInteraction(error)) {
            return instance!.acquireTokenPopup(request);
          }
          throw error;
        });
        dispatch({type: 'SET_ACCESS_TOKEN', payload: res.accessToken})
        return res;
      }
      throw new Error('MSAL instance not available');
    },
    [getAccount, instance, state.name]
  );

  useEffect(() => {
    if (!instance || !state.name) return;

    const refreshInterval = 40 * 60 * 1000; // 40 minutes in milliseconds

    const refreshToken = async () => {
      try {
        await acquireToken();
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    };

    const intervalId = setInterval(refreshToken, refreshInterval);

    // Initial token refresh when conditions are met
    refreshToken();

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [acquireToken, instance, state.name]);


  const onSignOut = useMemo(() => {
    return instance && instance.getActiveAccount() !== null
      ? () => {
        localStorage.removeItem('appState');
        dispatch({ type: 'SET_AVATAR_URL', payload: undefined });
        dispatch({ type: 'SET_NAME', payload: undefined });
        instance.logoutRedirect().catch((error) => console.log(error));;
      }
      : undefined;
  }, [instance]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (instance && instance.getActiveAccount() !== null) {
        const account = instance.getActiveAccount();
        if (account && account.idToken) {
          const [avatarURL] = await Promise.all([
            fetchProfilePhotoAsBase64(account.idToken),
          ]);
          dispatch({ type: 'SET_AVATAR_URL', payload: avatarURL });
        }
        dispatch({ type: 'SET_NAME', payload: account?.username });
      }
    };

    fetchProfile();
  }, [instance]);




  return <AppStateContext.Provider value={{ state, dispatch, onSignOut, acquireToken, getAccount }}>{children}</AppStateContext.Provider>
};