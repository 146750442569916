import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: '15bc183f-e5c9-42e6-8bf7-13f3616c216c', // This is the ONLY mandatory field that you need to supply.
        redirectUri: window.location.origin, // Points to window.location.origin. You must register this URI on Microsoft Entra admin center/App Registration.
        postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        authority: `https://login.microsoftonline.com/9693558b-a906-4962-9612-91f259a2bddb/oauth2/v2.0/token`
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, 
    },
};

export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read', 'Mail.Send', 'People.Read.All', 'People.Read'],
    loginHint: "",
    forceRefresh: true
};