import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { PublicClientApplication, EventType, AccountInfo, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account);
    window.location.reload();
  }
});

root.render(
  <FluentProvider theme={webLightTheme}>
  <App instance={msalInstance}/>
  </FluentProvider>);


