export const XSSAllowTags = [
    'iframe',
    'a',
    'img',
    'svg',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'div',
    'p',
    'span',
    'small',
    'del',
    'img',
    'pictrue',
    'embed',
    'video',
    'audio',
    'i',
    'u',
    'sup',
    'sub',
    'strong',
    'strike',
    'code',
    'pre',
    'body',
    'section',
    'article',
    'footer',
    'table',
    'tr',
    'td',
    'th',
    'thead',
    'tbody',
    'tfooter',
    'ul',
    'ol',
    'li'
  ]