import { useCallback, useContext, useMemo, useState } from 'react';
import { Stack, Persona, PersonaSize, ContextualMenu, Link, IContextualMenuItem, DefaultButton, FontIcon, mergeStyles, getTheme, mergeStyleSets } from '@fluentui/react';
import { AppStateContext } from '../../state/AppProvider';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { Button, Divider } from '@fluentui/react-components';
import styles from '../Layout/Layout.module.css';
type MicrosoftGraphProfileMenuProps = {
  collapsed: boolean;
};


const MicrosoftGraphProfileMenu = ({ collapsed }: MicrosoftGraphProfileMenuProps) => {
  const { instance } = useMsal();
  const appContext = useContext(AppStateContext)!;
  const [expanded, setExpanded] = useState(false);
  const signedIn = !!appContext.onSignOut;
  const { state } = appContext;

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.log(error));
  };

  const handleSignOut = useCallback(() => {
    if (appContext.onSignOut) {
      appContext.onSignOut();
      setExpanded(false);
    }
  }, [appContext]);


  const handleToggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  const menuItems: IContextualMenuItem[] = [
    state.name && {
      key: 'name',
      text: `Signed in as ${state.name}`,
      disabled: true,
    },
    {
      key: 'reviewAccess',
      text: 'Review access on Office.com',
      href: 'https://portal.office.com/account/#apps',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      key: 'signOut',
      text: 'Sign out',
      onClick: handleSignOut,
    },
  ].filter(Boolean) as IContextualMenuItem[];

  const theme = getTheme();

  const buttonClass = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: theme.palette.themePrimary,
    color: theme.palette.white,
    width: collapsed ? '50px' : '100%',
    minWidth: '40px',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: theme.palette.themeDark,
    },
  });

  const iconClass = mergeStyles({
    marginRight: collapsed ? 0 : 8, // Add space between icon and text only if not collapsed
  });

  const navClass = mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  });

  return (
    <Stack horizontalAlign="center" className={navClass.container}>
      {!signedIn && (
         <DefaultButton
         onClick={handleRedirect}
         className={buttonClass}
       >
        <FontIcon iconName="Contact"  className={iconClass}/>
        {!collapsed && "Sign in"}
       </DefaultButton>
      )}
      {signedIn && (
        <DefaultButton
          aria-label="Open profile menu"
          onClick={handleToggleExpand}
          className={buttonClass}
          style={{backgroundColor: 'white', borderColor: 'white'}}
        >
          <Persona
            imageUrl={state.avatarURL || './images/Microsoft-Graph-64px-DDD-White.png'}
            size={PersonaSize.size40}
            hidePersonaDetails
            className={iconClass}
            styles={{ root: { cursor: 'pointer' } }}
          />
        </DefaultButton>
      )}
      {signedIn && expanded && (
        <ContextualMenu
          items={menuItems}
          hidden={!expanded}
          target={'.ms-Persona'}
          onItemClick={handleToggleExpand}
          onDismiss={handleToggleExpand}
        />
      )}
    </Stack>
  );
};

export default MicrosoftGraphProfileMenu;
