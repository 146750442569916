
import './App.css';
import Layout from './ui/Layout/Layout';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Upload from './ui/FileUpload/Upload';
import OpenAI from './ui/OpenAI/OpenAI';
import { AppStateProvider } from './state/AppProvider';
import EmptyPage from './ui/Empty';
import ReadOnlyOpenAI from './ui/ReadOnlyOpenAI/ReadOnlyOpenAI';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import Blank from './ui/Blank';
import Feedback from './ui/Feedback/Feedback';

type AppProps = {
  instance: IPublicClientApplication;
};



function App({ instance }: AppProps) {
  return (
    <div className="container">
      <MsalProvider instance={instance}>
        <AppStateProvider>
          <HashRouter>
            <UnauthenticatedTemplate>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path='*' element={<EmptyPage />} />
                </Route>
              </Routes>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Layout />}>
              <Route index element={<OpenAI/>} />
              <Route path="upload" element={<Upload />} />
              <Route path="openai" element={<OpenAI />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="openai/:id" element={<ReadOnlyOpenAI />} />
              <Route path='*' element={<Blank />} />
              </Route>
            </Routes>
            </AuthenticatedTemplate>
          </HashRouter>
        </AppStateProvider>
      </MsalProvider>
    </div>
  );
}

export default App;
