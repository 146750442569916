
export const loadState = (key: string) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      console.error("Could not load state from localStorage:", err);
      return undefined;
    }
  };
  
  export const saveState = (key: string, state: any) => {
    try {
      // Prune the chatHistory by removing all messages after the 15th element
      if (state.chatHistory) {
        state.chatHistory = state.chatHistory.map((chat: any, index: number) => {
          if (index >= 15) {
            return {
              ...chat,
              messages: []
            };
          }
          return chat;
        });
      }
      const serializedState = JSON.stringify(state);
      console.log("state", state)

      // Check if the new state exceeds the local storage quota
      if (serializedState.length > 5000000) { // 5MB limit as an example
        console.warn("State size exceeds local storage quota. State not saved.");
        return;
      }

      localStorage.setItem(key, serializedState);
    } catch (err) {
      console.error("Could not save state to localStorage:", err);
    }
  };
  