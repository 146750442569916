import { Stack, Text, PrimaryButton, mergeStyleSets, getTheme } from '@fluentui/react';
import React, { useContext, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    useId,
    Link,
    Button,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody,
    ToastFooter,
    ToastIntent,
    ToastTrigger,
} from "@fluentui/react-components";
import useConversationApi from '../../api/api';
import { ApiStatus } from '../../api';
import styles from './Feedback.module.css';
import { Client } from '@microsoft/microsoft-graph-client';
import { AppStateContext } from '../../state/AppProvider';
import { Message } from '@microsoft/microsoft-graph-types';

const Feedback: React.FC = () => {
    const [editorContent, setEditorContent] = useState<string>('');
    const quillRef = useRef<ReactQuill>(null);
    const conversationApi = useConversationApi();
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const appStateContext = useContext(AppStateContext);

    const handleChange = (content: string) => {
        setEditorContent(content);
    };

    const graphClient = Client.init({
        authProvider: async (done) => {
          if(appStateContext?.acquireToken) {
            const token = await appStateContext?.acquireToken();
            done(null, token.accessToken);
          }
        },
      });

      graphClient.api('/me/send')

    const handleSubmit = async () => {
        const msg = {
            "message": {
              "subject": "Feedback for AiVars",
              "body": {
                "contentType": "HTML",
                "content": editorContent
              },
              "toRecipients": [  
                {
                    "emailAddress": {
                        "address": "martins.aldins@sorainen.com"
                    }
                },
                {
                    "emailAddress": {
                      "address": "martins.stamguts@sorainen.com"
                    }
                }
              ]
            },
            "saveToSentItems": "false"
          } as Message
        await graphClient.api('/me/sendMail').post(msg).then(() => {
            setEditorContent('');
            notify("success", "Email sent!", "Thank you for your feedback.");
        },() => {
            notify("error", "Email was not sent!", "Please try again later!");
          });
    };

    const notify = (status: ToastIntent, statusTitle: string, statusBody: string) =>
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    {statusTitle}
                </ToastTitle>
                <ToastBody>{statusBody}</ToastBody>
            </Toast>,
            { intent: status }
        );

    const modules = {
        toolbar: {
            container: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                ['image'],
                ['clean']
            ]
        }
    };

    const theme = getTheme();

    const classes = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 20,
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            boxShadow: theme.effects.elevation8,
            flexGrow: 1,
            maxWidth: '80%',
            overflow: 'auto', // Add this line to handle overflow
            maxHeight: '100vh', // Optional: To restrict the height of the container
        },
        descriptionBox: {
            padding: 20,
            backgroundColor: theme.palette.neutralLighter,
            borderRadius: 10,
            textAlign: 'center',
            marginBottom: 20,
            flexShrink: 0,
        }
    });

    return (
<Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { backgroundColor: '#f3f2f1', height: '100vh', overflow: 'auto' } }}>
<Stack className={classes.container}>
                <Toaster inline toasterId={toasterId} className={styles.customToast}/>
                <Stack tokens={{ childrenGap: 15, padding: 20 }}>
                    <Stack className={classes.descriptionBox} tokens={{ childrenGap: 15, padding: 20 }}>
                            <Text style={{alignSelf: 'start'}} variant="xxLarge">AiVars Feedback</Text>
                            <Text variant="medium" style={{textAlign: 'justify'}}>
                            Please structure your feedback in the following manner:
                            <ul>
<li>Which Section: Identify the specific part of the platform you are providing feedback on (e.g., "Left navigation panel", "Performance", "Chat History", "Chat about QMS" etc.).</li>
<li>What You Don't Like: Clearly describe the issue or aspect that you find problematic. Be specific and provide examples if possible (e.g., "The search function is too slow," "The dashboard layout is confusing").</li>
<li>Suggestion: Offer constructive suggestions on how to improve the identified issue (e.g., "Make the button dark red", "Add all of the buttons to the footer").</li>
</ul>
</Text>
                        </Stack>
                    <div className={styles.editorWrapper}>
                        <ReactQuill
                            ref={quillRef}
                            value={editorContent}
                            onChange={handleChange}
                            modules={modules}

                        />
                    </div>
                    <PrimaryButton style={{minHeight: '30px'}} onClick={handleSubmit}>Send Feedback</PrimaryButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Feedback;
