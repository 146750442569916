import { useContext, useState } from 'react'
import { Stack, TextField, Text } from '@fluentui/react'
import { SendRegular } from '@fluentui/react-icons'

import styles from './QuestionInput.module.css'
import { AppStateContext } from '../../state/AppProvider'

interface Props {
  onSend: (question: string, id?: string) => void
  disabled: boolean
  placeholder?: string
  clearOnSend?: boolean
  conversationId?: string
}

function extractFileName(path: string): string | null {
  const regex = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-(.+)$/;
  
  const match = path.match(regex);
  
  return match ? match[1] : null;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId }: Props) => {
  const [question, setQuestion] = useState<string>('')
  const appStateContext = useContext(AppStateContext)
  

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return
    }

    if (conversationId) {
      onSend(question, conversationId)
    } else {
      onSend(question)
    }

    if (clearOnSend) {
      setQuestion('')
    }
  }

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
      ev.preventDefault()
      sendQuestion()
    }
  }

  const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setQuestion(newValue || '')
  }

  const sendQuestionDisabled = disabled || !question.trim()
  const selectedFile = appStateContext?.state.selectedFile
  const fancyName = selectedFile ? extractFileName(selectedFile) : undefined;
  const usedFileText = appStateContext?.state.onlyUploadedFiles ? (fancyName && fancyName !== undefined && fancyName !== null ? ` and on file '${fancyName}'` : ' and all user files') : ''
  const selectedSources = [
    { condition: appStateContext?.state.onlyUploadedFiles, value: 'Documents' },
    { condition: appStateContext?.state.onlyQMS, value: 'QMS' },
    { condition: appStateContext?.state.onlyIKNOW, value: 'iKnow' },
    { condition: appStateContext?.state.onlyWIKI, value: 'Wiki' },
    { condition: appStateContext?.state.onlyDMSTEMPLATES, value: 'DMS Templates' },
  ];
  
  const selectedSettings = selectedSources
    .filter(source => source.condition)
    .map(source => source.value)
    .join(', ') || 'General LLM capabilities';
  
  

  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextField
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        multiline
        resizable={false}
        borderless
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
      />
      <div
        className={styles.questionInputSendButtonContainer}
        role="button"
        tabIndex={0}
        aria-label="Ask question button"
        onClick={sendQuestion}
        onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null)}>
        {sendQuestionDisabled ? (
          <SendRegular className={styles.questionInputSendButtonDisabled} />
        ) : (
          <SendRegular className={styles.questionInputSendButton} />
        )}
      </div>
      <Text variant="small" className={styles.topicHint}>
      { `Using ${selectedSettings}`}
      { usedFileText}
        </Text>
      <div className={styles.questionInputBottomBorder} />

    </Stack>
  )
}