import React, { useContext, useState } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { Stack, Text, IconButton, Link, FontIcon } from '@fluentui/react';
import MicrosoftGraphProfileMenu from '../MicrosoftGraphProfileMenu';
import styles from './Layout.module.css';
import { HistoryButton } from '../../components/common/Button';
import { AppStateContext } from '../../state/AppProvider';
import { CosmosDBStatus } from '../../api';
import Settings from '../../components/Settings/Settings';

const Layout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const appStateContext = useContext(AppStateContext)!
    const hideHistoryLabel = 'Hide chat history'
    const showHistoryLabel = 'Show chat history'
    const signedIn = !!appStateContext.onSignOut;
    const [settingsOpen, setSettingsOpen] = useState(false);
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };
    const location = useLocation();
    const isOpenAiPath = location.pathname === '/openai' || location.pathname === '/';
    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    }

    const handleSettingsClick = () => {
        setSettingsOpen(!settingsOpen);
    }

    return (
        <Stack horizontal className={collapsed ? styles.layoutCollapsed : styles.layout}>
            <Stack className={styles.sidebar} styles={{ root: { width: collapsed ? '60px' : '250px' } }}>
            <Settings open={settingsOpen} onDismiss={handleSettingsClick}/>
                <IconButton
                    iconProps={{ iconName: collapsed ? 'GlobalNavButton' : 'ChevronLeft' }}
                    onClick={toggleCollapse}
                    className={styles.collapseButton}
                />
                <Stack horizontalAlign="center" className={styles.logoContainer}>
                    <Link href="https://aivars.sorainen.com" rel="home">
                        <img
                            src="https://www.sorainen.com/wp-content/themes/sorainen/build/img/logo__sorainen.svg"
                            className={styles.logo}
                            alt="Sorainen"
                        />
                    </Link>
                </Stack>
                <Stack horizontalAlign="center" className={styles.profileContainer}>
                    <MicrosoftGraphProfileMenu collapsed={collapsed}/>
                </Stack>
                <nav>
                    <ul className={styles.navList}>
                        {/* <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                                <FontIcon iconName="Chat" className={styles.navIcon} />
                                {!collapsed && "Chat about knowledgebase"}
                            </NavLink>
                        </li> */}
                        <li className={styles.sectionHeader}>{!collapsed && "Menu"}</li>
                        <li>
                            <NavLink to="/upload" className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                                <FontIcon iconName="Upload" className={styles.navIcon} />
                                {!collapsed && "Document Upload"}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                                <FontIcon iconName="Chat" className={styles.navIcon} />
                                {!collapsed && "OpenAI"}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink target='_blank' rel='noopener noreferrer' to="/feedback" className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                                <FontIcon iconName="Feedback" className={styles.navIcon} />
                                {!collapsed && "Feedback"}
                            </NavLink>
                        </li>
                        <div className={styles.divider}></div>
                        {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured 
                        && signedIn && isOpenAiPath && (
                            <>
                        <li className={styles.sectionHeader}>{!collapsed && "Chat buttons"}</li>
                        <li>
                            <div onClick={handleSettingsClick} className={styles.navLink}>
                                <FontIcon iconName="Settings" className={styles.navIcon} />
                                {!collapsed && "Settings"}
                            </div>
                            <div onClick={handleHistoryClick} className={styles.navLink}>
                                <FontIcon iconName="History" className={styles.navIcon} />
                                {!collapsed &&<>
                                {appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel}
                                </>}
                            </div>
                        </li></>
                        )}
                        {/* <li>
                            <NavLink to="/dms" className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                                <FontIcon iconName="Chat" className={styles.navIcon} />
                                {!collapsed && "DMS"}
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </Stack>
            <Stack grow className={styles.content}>
                <Stack className={styles.developmentNotice} horizontalAlign="center" verticalAlign="center">
                    <Text variant="mediumPlus">This website is still under development and you might experience bugs.</Text>
                </Stack>
                <Stack grow className={styles.mainContent}>
                    <Outlet />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Layout;
