const Blank: React.FC = () => {

    return (
        <div className="container" style={{ backgroundColor: '#f3f2f1' }}>
      <div className='main-wrapper'>
      </div>
  </div>
    )
}

export default Blank;
