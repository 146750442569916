import { encode } from 'base64-arraybuffer';

export default async function fetchProfilePhotoInBase64(accessToken: string): Promise<string | undefined> {
  if (accessToken) {
    const res = await fetch('https://graph.microsoft.com/beta/me/photos/48x48/$value', {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    });

    if (!res.ok) {
      if (res.status === 401 || res.status === 404) {
        // Personal account does not have profile photo
        return './images/Microsoft-Graph-64px.png';
      } else {
        throw new Error('Microsoft Graph: Failed to fetch user profile photo.');
      }
    }

    return `data:${res.headers.get('content-type')};base64,${encode(await res.arrayBuffer())}`;
  }
}
