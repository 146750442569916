/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  IBasePickerSuggestionsProps,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IPersonaProps,
  ITag,
  ITextField,
  Label,
  List,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import {
    useId,
    Link,
    Button,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody,
    ToastFooter,
    ToastIntent,
    ToastTrigger,
} from "@fluentui/react-components";
import { useBoolean } from '@fluentui/react-hooks'

import useConversationApi from '../../api/api';
import { ApiStatus, Conversation } from '../../api/models'
import { AppStateContext } from '../../state/AppProvider'

import { GroupedChatHistory } from './ChatHistoryList'

import styles from './ChatHistoryPanel.module.css'
import { link } from 'fs';
import { CopyRegular } from '@fluentui/react-icons';
import { Person } from '@microsoft/microsoft-graph-types';
import { Client } from '@microsoft/microsoft-graph-client';
import { Message } from '@microsoft/microsoft-graph-types';

interface ChatHistoryListItemCellProps {
  item?: Conversation
  onSelect: (item: Conversation | null) => void
}

interface ChatHistoryListItemGroupsProps {
  groupedChatHistory: GroupedChatHistory[]
}

const formatMonth = (month: string) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const [monthName, yearString] = month.split(' ')
  const year = parseInt(yearString)

  if (year === currentYear) {
    return monthName
  } else {
    return month
  }
}

export const ChatHistoryListItemCell: React.FC<ChatHistoryListItemCellProps> = ({ item, onSelect }) => {
  const [edit, setEdit] = useState(false)
  const [editTitle, setEditTitle] = useState('')
  const [copyClicked, setCopyClicked] = useState<boolean>(false)
  const [copyText, setCopyText] = useState('Copy URL');
  const [isLoading, setIsLoading] = useState(false);
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false)
  const [hideDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(true)
  const [sharedLink, setSharedLink] = useState('');
  const [shareLabel, setShareLabel] = useState<string | undefined>('Share')
  const [errorDelete, setErrorDelete] = useState(false)
  const [renameLoading, setRenameLoading] = useState(false)
  const [errorRename, setErrorRename] = useState<string | undefined>(undefined)
  const [textFieldFocused, setTextFieldFocused] = useState(false)
  const textFieldRef = useRef<ITextField | null>(null)
  const convesationAPi = useConversationApi()
  const appStateContext = React.useContext(AppStateContext)
  const isSelected = item?.id === appStateContext?.state.currentChat?.id
  const [statusMessage, setStatusMessage] = useState('');
  const [selectedPeople, setSelectedPeople] = useState<IPersonaProps[]>([]);
  const picker = React.useRef(null);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  useEffect(() => {
    if (appStateContext?.state.currentChat?.id !== item?.id) {
      setEdit(false)
      setEditTitle('')
    }
  }, [appStateContext?.state.currentChat?.id, item?.id]);

  const graphClient = Client.init({
    authProvider: async (done) => {
      // Implement your token retrieval logic here
      if (appStateContext?.acquireToken) {
        const token = await appStateContext?.acquireToken(); // Replace with actual token retrieval
        done(null, token.accessToken);
      }
    },
  });
  // graphClient.api('/me/send')

  const handleEmailShare = async (persons: IPersonaProps[], shareLink: string) => {
      const emails = persons.map((person: IPersonaProps) => person.key!);
      console.log(emails);
      // Prepare recipients for the message
      const recipients = emails.map(email => ({
        emailAddress: {
          address: email
        }
      }));
      const msg = {
          "message": {
            "subject": "Conversation Shared with You from AiVar",
            "body": {
              "contentType": "HTML",
              "content": `
                <p>Hello,</p>
                <p>A conversation has been shared with you from AiVar. Here is the link to access it:</p>
                <p><a href="${shareLink}">${shareLink}</a></p>
                <p><strong>Access Information:</strong> This link is restricted to selected users. If you experience any difficulties accessing the conversation:</p>
                <ul>
                  <li>Please try logging out and then logging back into AiVar.</li>
                  <li>If the issue continues, contact the AiVar support team - KMI or your local IT.</li>
                </ul>
              `
            },
            "toRecipients": recipients
          },
          "saveToSentItems": "true"
        } as Message
      await graphClient.api('/me/sendMail').post(msg).then(() => {
        notify("success", "Conversation shared!", "Only users from the list will see the list.");
    },() => {
        notify("error", "Chat was not shared!", "Please try again or contact administrator!");
      });
  };
  
  const notify = (status: ToastIntent, statusTitle: string, statusBody: string) =>
    dispatchToast(
        <Toast appearance="inverted">
            <ToastTitle
                action={
                    <ToastTrigger>
                        <Link>Dismiss</Link>
                    </ToastTrigger>
                }
            >
                {statusTitle}
            </ToastTitle>
            <ToastBody>{statusBody}</ToastBody>
        </Toast>,
        { intent: status }
    );

  const searchPeople = async (query: string): Promise<Person[]> => {
    try {
      const response = await graphClient.api('/me/people')
        // .filter("personType eq 'Person'")
        .search(query)
        .select('id,displayName,userPrincipalName')
        .get();
      return response.value;
    } catch (e) {
      console.log('Failed to fetch people within organization...')
      return [];
    }
  };


  useEffect(() => {
    if (textFieldFocused && textFieldRef.current) {
      textFieldRef.current.focus()
      setTextFieldFocused(false)
    }
  }, [textFieldFocused]);
  const dialogContentProps = {
    type: DialogType.close,
    title: 'Are you sure you want to delete this item?',
    closeButtonAriaLabel: 'Close',
    subText: 'The history of this chat session will permanently removed.'
  }

  const modalProps = {
    titleAriaId: 'labelId',
    subtitleAriaId: 'subTextId',
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  }

  if (!item) {
    return null
  }





  const onDelete = async () => {
    const response = await convesationAPi.historyDelete(item.id)
    if (!response.ok) {
      setErrorDelete(true)
      setTimeout(() => {
        setErrorDelete(false)
      }, 5000)
    } else {
      appStateContext?.dispatch({ type: 'DELETE_CHAT_ENTRY', payload: item.id })
    }
    toggleDeleteDialog()
  }

  const onEdit = () => {
    setEdit(true)
    setTextFieldFocused(true)
    setEditTitle(item?.title)
  }



  const handleSelectItem = () => {
    onSelect(item)
    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: item })
  }

  const truncatedTitle = item?.title?.length > 28 ? `${item.title.substring(0, 28)} ...` : item.title

  const handleSaveEdit = async (e: any) => {
    e.preventDefault()
    if (errorRename || renameLoading) {
      return
    }
    if (editTitle == item.title) {
      setErrorRename('Error: Enter a new title to proceed.')
      setTimeout(() => {
        setErrorRename(undefined)
        setTextFieldFocused(true)
        if (textFieldRef.current) {
          textFieldRef.current.focus()
        }
      }, 5000)
      return
    }
    setRenameLoading(true)
    const response = await convesationAPi.historyRename(item.id, editTitle)
    if (!response.title || response.title !== editTitle) {
      setErrorRename('Error: could not rename item')
      setTimeout(() => {
        setTextFieldFocused(true)
        setErrorRename(undefined)
        if (textFieldRef.current) {
          textFieldRef.current.focus()
        }
      }, 5000)
    } else {
      setRenameLoading(false)
      setEdit(false)
      appStateContext?.dispatch({ type: 'UPDATE_CHAT_TITLE', payload: { ...item, title: editTitle } as Conversation })
      setEditTitle('')
    }
  }

  const chatHistoryTitleOnChange = (e: any) => {
    setEditTitle(e.target.value)
  }

  const cancelEditTitle = () => {
    setEdit(false)
    setEditTitle('')
  }

  const handleKeyPressEdit = (e: any) => {
    if (e.key === 'Enter') {
      return handleSaveEdit(e)
    }
    if (e.key === 'Escape') {
      cancelEditTitle()
      return
    }
  }



  const handleSnapshotCreation = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const conversationId = item.id;
      if (!conversationId) {
        throw new Error('No chat selected...');
      }
      const response = await convesationAPi.createSnapshot(conversationId, selectedPeople);

      if (response.status === ApiStatus.success) {
        setSharedLink(window.location.origin + '/#/openai/' + conversationId); // Assuming the link is returned in the response
        await handleEmailShare(selectedPeople, window.location.origin + '/#/openai/' + conversationId);
        setStatusMessage('Conversation snapshot created successfully!');
      } else {
        setStatusMessage('Failed to create a snapshot of the conversation.');
      }
    } catch (error) {
      setStatusMessage('An error occurred while creating a snapshot of the conversation.');
    }

    setIsLoading(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(sharedLink)
    setCopyClicked(true)
  }


  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false)
    setCopyClicked(false)
    setCopyText('Copy URL')
    setSharedLink('')
    setStatusMessage('')
  }

  const handleShareClick = () => {
    setIsSharePanelOpen(true)
  }


  const classes = mergeStyleSets({
    dialog: {
      '@media (min-width: 480px)': {
        width: '60%',
        maxWidth: '600px',
        background: '#FFFFFF',
        boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        minHeight: '200px'
      },
      boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      overflow: 'hidden'
    },
    shareButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: '20px',
      gap: '10px',
      width: '100%',
      selectors: {
        'button': {
          flex: 1,
          margin: '0 5px' // Optional: Add some margin between buttons
        }
      }
    },
    copyButton: {
      cursor: 'pointer'
    },
    copyButtonText: {
      marginLeft: '8px'
    }
  });

  const onResolveSuggestions = async (filterText: string): Promise<ITag[]> => {
    if (filterText.length > 2) {
      const people = await searchPeople(filterText);
      return people.filter(x => x.userPrincipalName).map(person => ({
        key: person.userPrincipalName!,
        name: person.displayName!,
        text: person.displayName!,
      }));
    }
    return [];
  };

  const onItemSelected = (item?: IPersonaProps): IPersonaProps | null => {
    if (item) {
      setSelectedPeople(prevUsers => [...prevUsers, item]);
      return item;
    }
    return null;
  };

  const handleSelectedUsersChange = (items?: IPersonaProps[]) => {
    if (items) {
      setSelectedPeople(items);
    }
  };


  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
  };

  const menuItems: IContextualMenuProps = {
    items: [
      {
        key: '0',
        text: 'Edit',
        iconProps: { iconName: 'Edit' },
        onClick: onEdit,
      },
      {
        key: '1',
        text: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: handleShareClick,
      },
      {
        key: '2',
        text: 'Delete',
        iconProps: { iconName: 'Delete' },
        onClick: toggleDeleteDialog,
      },
    ]
  };



  return (
    <Stack
      key={item.id}
      tabIndex={0}
      aria-label="chat history item"
      className={styles.itemCell}
      verticalAlign="center"
      // horizontal
      styles={{
        root: {
          backgroundColor: isSelected ? '#e6e6e6' : 'transparent'
        }
      }}>

      <Dialog
        onDismiss={handleSharePanelDismiss}
        hidden={!isSharePanelOpen}
        dialogContentProps={{
          title: 'Share the web app',
          showCloseButton: true
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: classes.dialog }
        }}
      >
        <Stack style={{ gap: '16px', width: '100%' }}>
          <Stack.Item style={{ width: '100%' }}>
            <Label>Search for people</Label>
            <NormalPeoplePicker
              onResolveSuggestions={onResolveSuggestions}
              getTextFromItem={(item: IPersonaProps) => item.text || ''}
              ref={picker}
              key={'normal'}
              enableSelectedSuggestionAlert
              pickerSuggestionsProps={suggestionProps}
              className={'ms-PeoplePicker'}
              selectedItems={selectedPeople}
              inputProps={{
                'aria-label': 'People Picker',
              }}
              onChange={handleSelectedUsersChange}
              onItemSelected={onItemSelected}
            />
          </Stack.Item>
          <Stack.Item style={{ width: '100%' }}>
            <PrimaryButton style={{ width: '100%' }} text="Share the conversation" onClick={handleSnapshotCreation} disabled={isLoading} />
          </Stack.Item>
          {isLoading && <Spinner label="Sharing..." />}
          {statusMessage && (
            <MessageBar
              messageBarType={sharedLink ? MessageBarType.success : MessageBarType.error}
              isMultiline={false}
            >
              {statusMessage}
            </MessageBar>
          )}
          {sharedLink && (
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
              <TextField styles={{ root: { width: '70%' } }} defaultValue={sharedLink} readOnly />
              <div
                role="button"
                tabIndex={0}
                aria-label="Copy"
                onClick={handleCopyClick}
                onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null)}
                className={classes.copyButton}
              >
                <CopyRegular />
                <span className={classes.copyButtonText}>{copyText}</span>
              </div>
            </Stack>
          )}
        </Stack>
      </Dialog>

      <Stack horizontal verticalAlign={'center'} style={{ width: '100%' }}>
        <div onClick={() => handleSelectItem()}
          onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? handleSelectItem() : null)}
          className={styles.chatTitle}>{truncatedTitle}</div>
        <Stack horizontal horizontalAlign="end">
          <CommandBarButton
            className={styles.itemButton}
            iconProps={{ iconName: 'More' }}
            role="button"
            title="More options"
            menuProps={menuItems}
          />
        </Stack>
      </Stack>
      {edit && (
        <>
          <Stack.Item style={{ width: '100%' }}>
            <form aria-label="edit title form" onSubmit={e => handleSaveEdit(e)} style={{ padding: '5px 0px' }}>
              <Stack horizontal verticalAlign={'start'}>
                <Stack.Item>
                  <TextField
                    componentRef={textFieldRef}
                    autoFocus={textFieldFocused}
                    value={editTitle}
                    placeholder={item.title}
                    onChange={chatHistoryTitleOnChange}
                    onKeyDown={handleKeyPressEdit}
                    // errorMessage={errorRename}
                    disabled={errorRename ? true : false}
                  />
                </Stack.Item>
                {editTitle && (
                  <Stack.Item>
                    <Stack aria-label="action button group" horizontal verticalAlign={'center'}>
                      <IconButton
                        role="button"
                        disabled={errorRename !== undefined}
                        onKeyDown={e => (e.key === ' ' || e.key === 'Enter' ? handleSaveEdit(e) : null)}
                        onClick={e => handleSaveEdit(e)}
                        aria-label="confirm new title"
                        iconProps={{ iconName: 'CheckMark' }}
                        styles={{ root: { color: 'green', marginLeft: '5px' } }}
                      />
                      <IconButton
                        role="button"
                        disabled={errorRename !== undefined}
                        onKeyDown={e => (e.key === ' ' || e.key === 'Enter' ? cancelEditTitle() : null)}
                        onClick={() => cancelEditTitle()}
                        aria-label="cancel edit title"
                        iconProps={{ iconName: 'Cancel' }}
                        styles={{ root: { color: 'red', marginLeft: '5px' } }}
                      />
                    </Stack>
                  </Stack.Item>
                )}
              </Stack>
              {errorRename && (
                <Text
                  role="alert"
                  aria-label={errorRename}
                  style={{ fontSize: 12, fontWeight: 400, color: 'rgb(164,38,44)' }}>
                  {errorRename}
                </Text>
              )}
            </form>
          </Stack.Item>
        </>
        // ) : (
        //   <>
        //     <Stack horizontal verticalAlign={'center'} style={{ width: '100%' }}>
        //       <div className={styles.chatTitle}>{truncatedTitle}</div>
        //       {(isSelected || isHovered) && (
        //         <Stack horizontal horizontalAlign="end">
        //           <IconButton
        //             className={styles.itemButton}
        //             iconProps={{ iconName: 'Delete' }}
        //             title="Delete"
        //             onClick={toggleDeleteDialog}
        //             onKeyDown={e => (e.key === ' ' ? toggleDeleteDialog() : null)}
        //           />
        //           <IconButton
        //             className={styles.itemButton}
        //             iconProps={{ iconName: 'Edit' }}
        //             title="Edit"
        //             onClick={onEdit}
        //             onKeyDown={e => (e.key === ' ' ? onEdit() : null)}
        //           />
        //         </Stack>
        //       )}
        //     </Stack>
        //   </>
      )}
      {errorDelete && (
        <Text
          styles={{
            root: { color: 'red', marginTop: 5, fontSize: 14 }
          }}>
          Error: could not delete item
        </Text>
      )}
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={toggleDeleteDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}>
        <DialogFooter>
          <PrimaryButton onClick={onDelete} text="Delete" />
          <DefaultButton onClick={toggleDeleteDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </Stack>
  )
}

export const ChatHistoryListItemGroups: React.FC<ChatHistoryListItemGroupsProps> = ({ groupedChatHistory }) => {
  const appStateContext = useContext(AppStateContext)
  const observerTarget = useRef(null)
  const [, setSelectedItem] = React.useState<Conversation | null>(null)
  const [offset, setOffset] = useState<number>(25)
  const [observerCounter, setObserverCounter] = useState(0)
  const [showSpinner, setShowSpinner] = useState(false)
  const firstRender = useRef(true)
  const conversationAPI = useConversationApi()

  const handleSelectHistory = (item?: Conversation) => {
    if (item) {
      setSelectedItem(item)
    }
  }

  const onRenderCell = (item?: Conversation) => {
    return <ChatHistoryListItemCell item={item} onSelect={() => handleSelectHistory(item)} />
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    handleFetchHistory()
    setOffset(offset => (offset += 25))
  }, [observerCounter])

  const handleFetchHistory = async () => {
    const currentChatHistory = appStateContext?.state.chatHistory
    setShowSpinner(true)

    await conversationAPI.historyList(offset).then((response: any) => {
      const concatenatedChatHistory = currentChatHistory && response && currentChatHistory.concat(...response)
      if (response) {
        appStateContext?.dispatch({ type: 'FETCH_CHAT_HISTORY', payload: concatenatedChatHistory || response })
      } else {
        appStateContext?.dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null })
      }
      setShowSpinner(false)
      return response
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) setObserverCounter(observerCounter => (observerCounter += 1))
      },
      { threshold: 1 }
    )

    if (observerTarget.current) observer.observe(observerTarget.current)

    return () => {
      if (observerTarget.current) observer.unobserve(observerTarget.current)
    }
  }, [observerTarget])

  return (
    <div className={styles.listContainer} data-is-scrollable>
      {groupedChatHistory.map(
        group =>
          group.entries.length > 0 && (
            <Stack
              horizontalAlign="start"
              verticalAlign="center"
              key={group.month}
              className={styles.chatGroup}
              aria-label={`chat history group: ${group.month}`}>
              <Stack aria-label={group.month} className={styles.chatMonth}>
                {formatMonth(group.month)}
              </Stack>
              <List
                aria-label={`chat history list`}
                items={group.entries}
                onRenderCell={onRenderCell}
                className={styles.chatList}
              />
              <div ref={observerTarget} />
              <Separator
                styles={{
                  root: {
                    width: '100%',
                    position: 'relative',
                    '::before': {
                      backgroundColor: '#d6d6d6'
                    }
                  }
                }}
              />
            </Stack>
          )
      )}
      {showSpinner && (
        <div className={styles.spinnerContainer}>
          <Spinner size={SpinnerSize.small} aria-label="loading more chat history" className={styles.spinner} />
        </div>
      )}
    </div>
  )
}